.divider-box{
  padding: 50px 0 50px;
}

.divider-simple{
  padding: 50px 0 50px;
}

.divider-grey{
  padding: 50px 0px 50px 0px;
  background-color: #f5f5f5;
 // border: 1px solid #dedede;
  //box-shadow: inset 0 1px 1px white;
  margin:0;
}

.divider-white{
  padding: 50px 0px 50px 0px;
  background-color: #fff;
}

.divider-black{
  padding: 50px 0 50px 0;
  background-color: @mycolor-black;
  h1,h2,h3,h4,a{color: #fff}
}

.divider-grey0{
  background: #EDF1F2;
  padding:0;
}



.divider-darkgrey{
  background: #6E7C85;
}

.divider-footerblue{
  padding: 30px 0 30px;
  background-color: @site-footer-bg;
  color: #fff;
  h1,h2,h3,h4,a{color: #fff}
}


.divider-testimonials{
  padding: 20px 0;
 // background-image: url('../images/blackfamily.jpg');
 // background-position: 50% 50%;
  background-size: cover;
  margin:0;

  h1,h2,h3,p{
    color: #fff;
  }

}

.divider-inpagesearch{
  padding:10px 0 5px 0;
  text-align:center;
  //background: @brand-color;
  background: #f5f5f5;
}