.logo-inverted{
  display:none;
}

.fixed-nav #header,.fixed-nav #header.homepage{
  position:fixed;
  //background: rgba(255,255,255,.9);

  top:0;

  .top-info{
    display:none;
  }

  .mobile-trigger i{
    color: #000;
  }



  .header-title{
    margin-top:0;
  }


  .header-title .logo img {
      max-width: 200px;
      max-height: 40px;
    }

    .site-main-nav ul li > a{
      line-height: 20px;
      height: 20px;
    }

  #mobilenav{
    margin-top:10px;
  }

  .favorites-info{
    margin-top:5px;
  }

//  .site-main-nav ul li > a{
//    color:@site-nav-color;
//  }

  .logo-inverted{
    display:none;
  }

  .logo-default{
    display:block;
  }
}

.header-title{
  padding:10px 0;
}

#header.homepage{
  position:absolute;
  border-bottom: none;

  .header-outter{
    background:fade(#000, 50);
  }
//  background: rgba(0,0,0,.2);
//  .site-main-nav ul li > a{
//    color: #fff;
//  }
//
//  .logo-inverted{
//    display:block;
//  }
//
//  .logo-default{
//    display:none;
//  }
//
//  .mobile-trigger i{
//    color: #fff;
//  }
//
//  .social-box {
//    color: #fff;
//    a {
//      color: #fff;
//    }
//  }
}

.header{
  //background: @brand-color2;
//  padding-bottom: 10px;
 // background-color: #fff;


  z-index:2;
  position:relative;

  border-bottom: 1px solid #f0f0f0;
}

.favorites-info{
  //position:absolute;
  //top: 20px;
  //right: 20px;
  display: none;
  float:left;
  margin-left: 20px;
  margin-top: 15px;
  &.active{
    display: block;
  }
  .favwrapper{
    position: relative;
    display: block;
    width: 25px;
    height: 30px;
  }
  #favcount{
    position:absolute;
    text-align: center;
    display: block;
    width: 100%;
    color: #fff;
    font-size: 11px;
  }
  .fa{
    position:absolute;
    font-size: 25px;
    color: red;
  }
}
.header{
  z-index: 10;
  &:after{
    content: "";
    display: table;
    clear: both;
  }
}



.header, .footer{
  .top-info{
    padding: 10px 5px 10px 5px;
    //border-bottom: 1px solid #f0f0f0;
    background: @header-info-bg;
  }
  .info-box{
    float:right;
  }

  .info-box-item{
    display:inline-block;
    padding: 0;
    font-size: 13px;
    //color: #9E9E9E;
    color: @header-info-color;
   // color: #fff;
 //   font-weight: bold;
  &:after{
    content:"•";
    display:inline-block;
    padding:0 5px;
    color:@header-info-color;
  }
    i{
      margin-right: 3px;
    }

    &:last-child{
      &:after{
        content:'';
      }
    }

    a{
      color: @header-info-color;
      //color: #fff;
    }
  }

  .social-box{
   float:left;
    margin-left:15px;
    color: @header-info-color;
    a{
      color: @header-info-color;
      display: inline-block;
      font-size:15px;
      &:hover{
        opacity:1;
      }
    }

    &:after{
      content: "";
      display: table;
      clear: both;
    }
  }

  .header-info{
    float:right;
    margin-top: 30px;

    .info-box-item{
      display:inline-block;
      padding: 0 25px;
      color: @header-info-color;
    &.with-border{
      border-right: 1px solid #DDDDDD;
    }
      a{
        color: @header-info-color;
      }
      i{
        color: @brand-color;
        font-size: 40px;
        float:left;
      }

      .info-box-content{
        overflow:hidden;
        padding-left:20px;
        font-size: 16px;
        color: #545454;
        font-weight: 300;
        span{
          color: #000;
        }
      }
    }
  }

}




.site-main-nav-wrapper{
  background-color: @brand-color;
   color: #fff;
}

.header {
 // background-color: #1E88E5;
 // color: #fff;
  display: block;
//  height: 1px;
  z-index: 100;
  width: 100%;
}



.header .logo img{
  //max-width:650px;
  max-width: 450px;
  max-height: 100px;
}

.logo_addon{
  width: 160px;
  display: block;
}

.header-outter{
  //background:@brand-color;
  background:@header-bg;
}

.header-inner {
  position: relative; }
.header-standard .header-inner {
//  padding:10px 0 10px;

}
.header-minimal .header-inner {
  padding: 10px 0px; }

.header-large .header-main {
  margin: 37px 0px; }

.header-title {
  float: left;
  font-size: 30px;
  margin-top: -35px;
}

.header-addon{
  float:right;
  margin-top:20px;
}
.header-minimal .header-title {
  font-size: 24px;
  margin-top: 8px; }
.header-title img {
  margin-right: 15px;
  //opacity: .5;
  //transition: opacity .15s linear;
  vertical-align: -8px; }

@media (min-width: 768px) and (max-width: 991px) {
  .header-title img {
    margin-right: 0px; } }
.header-title a {
  color: #fff; }
.header-minimal .header-title a {
  color: #424242; }
.header-minimal.primary .header-title a {
  color: #fff; }
.header-title a:hover {
  color: #fff;
  text-decoration: none; }
.header-minimal .header-title a:hover {
  color: #424242; }
.header-minimal.primary .header-title a:hover {
  color: #fff; }
.header-title a:active, .header-title a:focus {
  text-decoration: none; }
@media (min-width: 768px) and (max-width: 991px) {
  .header-title span {
    display: none; } }
.header-title:hover img {
  opacity: 1; }

.header-title-image {
  fill: #90CAF9;
  height: 36px;
  margin-right: 10px;
  vertical-align: -6px;
  width: 36px; }

.header-search {
  float: right;
  margin-left: 100px;
  width: 550px; }
@media (max-width: 767px) {
  .header-search {
    clear: both;
    float: none;
    margin: 0px;
    padding: 20px 0px 0px 0px;
    width: 100%; } }
.header-search .input-group {
  margin: 0px; }
.header-search .form-control {
  background-color: transparent;
  box-shadow: none;
  border: 0px;
  border-bottom: 2px solid #90CAF9;
  border-radius: 0px;
  color: #fff;
  font-size: 30px;
  height: 60px;
  padding: 0px;
  transition: border-color .15s linear; }
.header-search .form-control::-webkit-input-placeholder {
  color: #fff; }
.header-search .form-control:-moz-placeholder {
  color: #fff; }
.header-search .form-control::-moz-placeholder {
  color: #fff; }
.header-search .form-control:-ms-input-placeholder {
  color: #fff; }
.header-search .form-control:focus {
  border-bottom-color: #fff; }
.header-search .form-control:focus ~ .input-group-btn .btn, .header-search .form-control:focus ~ .input-group-btn .btn-secondary {
  border-bottom-color: #fff; }
.header-search .input-group-btn .btn, .header-search .input-group-btn .btn-secondary {
  background-color: transparent;
  border: 0px;
  border-bottom: 2px solid #90CAF9;
  border-radius: 0px;
  box-shadow: none;
  height: 60px;
  transition: border-color .15s linear; }
.header-search .input-group-btn .btn i, .header-search .input-group-btn .btn-secondary i {
  font-size: 28px;
  vertical-align: middle; }
.header-search .input-group-btn .btn:hover, .header-search .input-group-btn .btn-secondary:hover, .header-search .input-group-btn .btn:focus, .header-search .input-group-btn .btn-secondary:focus {
  color: #fff;
  outline: 0px !important; }
.header-search .input-group-btn .btn:after, .header-search .input-group-btn .btn-secondary:after, .header-search .input-group-btn .btn:before, .header-search .input-group-btn .btn-secondary:before {
  display: none; }


.header-top {
  margin: 20px 0px 30px 0px; }
.header-top ul {
  float: right;
  margin: 0px;
  padding: 0px; }
.header-top ul li {
  margin: 0px 0px 0px 30px; }
.header-top ul li a {
  color: #64B5F6;
  font-weight: 500;
  padding: 0px;
  transition: color .15s linear; }
.header-top ul li a:hover {
  background-color: transparent;
  color: #BBDEFB; }
.header-top ul li a:active, .header-top ul li a:focus {
  background-color: transparent; }
.header-top .social {
  margin-left: 30px;
  margin-top: 2px; }
.header-top .social li {
  margin: 0px 8px; }
.header-top .social li i {
  font-size: 16px;
  margin: 0px 2px; }
.header-top .social li:last-child {
  margin-right: 0px; }

.header-topbar {
  background-color: @header-info-bg;
  color: @header-info-color;
  padding: 7px 0px 7px 0px;
  min-height: 36px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 11px;
  font-weight: bold;

}

.header-topbar-left {
  float: left; }
@media (max-width: 767px) {
  .header-topbar-left {
    float: none; } }

.header-topbar-right {
  float: right; }
@media (max-width: 767px) {
  .header-topbar-right {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    float: none;
    margin-top: 8px;
    padding-top: 8px; } }

.header-topbar-social {
  display: inline;
  margin: 0px;
  padding: 0px; }
.header-topbar-social li {
  display: inline;
  list-style: none;
  margin: 0px 0px 0px 10px;
  padding: 0px; }
.header-topbar-social li a {
  color: #BBDEFB;
  font-size: 15px;
  vertical-align: middle; }
.header-topbar-social li a:hover {
  color: #E3F2FD; }

.header-topbar-links {
  display: inline; }
.header-topbar-links li {
  display: inline;
  padding-left: 10px;
  vertical-align: middle; }
.header-topbar-links li:first-child {
  padding-left: 0px; }
.header-topbar-links li img {
  margin: -2px 5px 0px 0px;
  vertical-align: middle; }
.header-topbar-links li a {
  color: #E3F2FD; }
.header-topbar-links li a:hover {
  text-decoration: none; }


@media(max-width: @screen-sm){
  .header-topbar{
    display:none;

  }
  .header-standard .header-inner{
   // padding:15px 0;
  }

  .logo_addon{
    display:none;
  }
  .header-title{
    .logo{
      display: inline-block;
      img{
        max-width: 200px !important;
      }
    }

    float:none;
    margin:0 auto;
    text-align: center;
  }
}

@media(max-width: @screen-lg){
  .top-info{
    display:none;
  }
  .favorites-info {
    position:absolute;
    top: 20px;
    right: 20px;
    margin-top:0;
  }
  .header-topbar{
    display: none;
  }

  #nav-wrapper{
    display:none;
  }

  .header-title{
    .logo{
      display: inline-block;
      img{
        max-width: 400px;
      }
    }

    float:none;
    margin:0 auto;
    text-align: center;
  }
}

@media(max-width: @screen-md)
{
  .header-title{
    .logo{
      display: inline-block;
      img{
        max-width: 400px;
      }
    }

    float:none;
    margin:0 auto;
    text-align: center;
  }


.header-addon{
  display: none;
}
  .logo_addon{
    display:none;
    margin:0;
  }
}

.top-phone{
  position:fixed;
  background: @brand-color;
  padding: 10px 20px;
  font-size: 22px;
  right:0;
  top: 0;

  &.info-box-item{
    font-size: 16px;
    font-weight: bold;
    .fa{
      font-size: 22px;
    }
  }
}