//.bx-wrapper img{
//  display:block;
//  max-width: 100%;
//}
//
//.bx-wrapper div{
//  display:block;
//  max-width: 100%;
//}

.homepage-slider{
  .property-slider-wrapper{
    height: 500px;
  }
  .property-slider-item{
    min-height: 500px;
  }

  padding:0;

}

.homepage .owl-carousel{
  width: 80%;
  margin: 0 auto;
}


.owl-carousel-slideshow{
  .owl-dots{
    margin-top:-40px !important;
    position: relative;
  }
}

@media(max-width: @screen-lg) {
  .homepage-slider{
    margin-top:0;
  }
}


.properties-slider-wrapper{
  height: 570px;
  overflow:hidden;


}


.property-slider-item{
  min-height: 570px;
 // background-color: #464646;
  position:relative;
  color: #fff;


  .property-slider-item-location{
    display: block;
    font-size: 13px;
    color: #696969;
    text-transform: inherit;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.05em;

    i{
      color: @mycolor-red;
    }
  }

  .property-slider-item-meta-citem{
    padding-right: 10px;
    i{
      &:before{
        font-size: 22px;
        color: #696969;
      }
    }
  }

  .property-slider-info-wrapper{
    z-index:1;
    max-width: 450px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height:650px;

  }

  .property-slider-info{
    padding: 25px;
    background: @brand-color;



    .property-slider-leftinfo{
      max-width: 360px;
    }

    .label-wrapper{
      position:relative;
      margin-bottom:20px;
      display:block;
      left:0;
    }


    .property-box-list-meta{
      margin-top:15px;
    }
    .property-slider-more{
     background: @brand-color;
      color: #fff;
      position:absolute;
      bottom:0;
      right: 0;
      padding: 5px 10px;

    &:hover{
      background: fade(@brand-color, 80%);
      text-decoration: none;
    }
      span{
        display: inline-block;
        padding: 0 10px;
      }
    }

    h3{

      color: #fff;
      text-decoration: none;
      margin-top:0;
     // padding: 5px 10px 0px 10px;
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 400;

      a{
        color: #fff;
        text-decoration: none;
      }
    }

    .property-box-meta-citem{
      color: #fff;
      i{
        color: #fff;

        &:before{
          font-size: 22px;
        }
      }
    }

    .property-slider-price{
      font-size: 22px;
      font-weight: 600;
      color: #fff;
      margin-top:20px;
      float:right;
    }

    .property-slider-view{
      color: @mycolor-red;
      float: right;
    }
  }

  .property-slider-meta{
    margin-top:20px;
  }
}

@media(max-width: @screen-lg)
{
  .properties-slider-wrapper,.property-slider-item  {
    min-height: 650px;
  }
}

@media(max-width: @screen-md)
{
  .properties-slider-wrapper,.property-slider-item  {
    min-height: 550px;
  }
}

@media(max-width: @screen-sm)
{
  .properties-slider-wrapper,.property-slider-item  {
    min-height: 350px;
  }
}